import { useState, useEffect } from "react";
import useEarth, { EarthResource } from "./useEarth";
import { useRouteMatch, useHistory } from "react-router-dom";

export interface ClientAccountAttributes {
  name: string,
  website_url: string,
  google_ads_client_id: string,
  twitter_screen_name: string,
  dashboard_url: string,
  slug: string,
  products: {
    id: string,
    type: string
  }[],
  services: {
    id: string,
    type: string
  }[]
}

export type ClientAccount = EarthResource<ClientAccountAttributes>;

export default function useClientAccounts() {
  const match = useRouteMatch<{slug: string}>("/:slug/:year?/:month?");
  const history = useHistory();
  const {data: allAccounts, status} = useEarth<ClientAccount[]>("/api3/client-accounts");
  const [currentAccount, setCurrentAccount] = useState<null | ClientAccount>(null);

  function goToAccount(account: ClientAccount) {
    if (!currentAccount || (currentAccount && currentAccount.data.id !== account.data.id)) {
      history.push(`/${account.data.attributes.slug}`);
    } 
  }
  
  useEffect(() => {
    const slug = match?.params.slug;
    if (allAccounts && allAccounts.length > 0 && slug) {
      if (currentAccount && currentAccount.data.attributes.slug === slug) return;
      for (const acc of allAccounts) {
        if (acc.data.attributes.slug === slug) {
          setCurrentAccount(acc);
          return;
        }
      }
      history.push(`/${allAccounts[0].data.attributes.slug}`);
    }
    setCurrentAccount(null);
  }, [match, currentAccount, setCurrentAccount, allAccounts, history])
  
  useEffect(()=>{
    if (!match?.params.slug && allAccounts && allAccounts.length > 0 && !currentAccount) {
      history.push(`${allAccounts[0].data.attributes.slug}`);
    }
  }, [allAccounts, currentAccount, history, match])
  return {allAccounts, currentAccount, goToAccount, status};
}

export function useClientAccount(slug: string) {
  const {data, status, ENDPOINT_STATUSES} = useEarth<ClientAccount[]>("/api3/client-accounts");
  const [account, setAccount] = useState<ClientAccount | null>(null);
  const [accountStatus, setAccountStatus] = useState<number>(ENDPOINT_STATUSES.LOADING);
  useEffect(()=>{
    if (!data) return;
    data.forEach((report) => {
      if (report.data.attributes.slug === slug) {
        setAccount(report)
      };
    });
    setAccountStatus(ENDPOINT_STATUSES.SUCCESS);
  },[data, slug, ENDPOINT_STATUSES]);

  useEffect(()=>{
    if (status !== ENDPOINT_STATUSES.SUCCESS) {
      setAccountStatus(status);
    }
  },[status, ENDPOINT_STATUSES]);
  return {account, status: accountStatus, ENDPOINT_STATUSES }
}