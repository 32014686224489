import React from 'react'
import {Card, CardProps} from "../../components";
import {ClientAccount} from "../hooks/useClientAccounts";

export interface AccountSwitcherCardProps extends CardProps {
  onSelect: React.ChangeEventHandler,
  currentAccount: ClientAccount | null,
  allAccounts: ClientAccount[] | null
}

export default function AccountSwitcherCard ({onSelect, currentAccount, allAccounts}: AccountSwitcherCardProps) {
  if ( !allAccounts || allAccounts.length < 2 ) return (null);
  return (
    <Card>
      <Card.Subtitle>Switch Account</Card.Subtitle>
      <select className="px-2 py-3 w-full my-3 border-2 rounded text-gray-500 border-gray-300 hover:border-gray-400 hover:text-gray-800 transition-colors cursor-pointer" onChange={onSelect} value={currentAccount ? currentAccount.data.id : ""}>
        {allAccounts && allAccounts.map( acc => <option key={acc.data.id} value={acc.data.id}>{acc.data.attributes.name}</option> )}
      </select>
    </Card>
  );
}
