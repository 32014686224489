import {useEndpoint, ENDPOINT_STATUSES} from "ssomg-react";
// (window as any).api = new Ssomg.Network("https://admin.roardigital.co.uk","5d541383aa4de70011c74e20");
// (window as any).api = new Ssomg.Network("http://localhost:5050","5d5c0a4aaf9c8c0011483c8c");
// (window as any).api = new Ssomg.Network("https://admin.staging.roardigital.co.uk","5d5e724d111cfb0011db7fc7");

export interface EarthResource<ResourceAttributes> {
  data: {
    id: string,
    type: string,
    attributes: ResourceAttributes
  }
}

export interface UseEarthReturns<Resource> {
  data: null | Resource,
  status: number,
  ENDPOINT_STATUSES: typeof ENDPOINT_STATUSES,
  refresh: () => void
}

export default function useEarth<Resource>(endpoint?: string) : UseEarthReturns<Resource> {
  const { data, status, ENDPOINT_STATUSES, refresh } = useEndpoint(endpoint, {}, "5d541383aa4de70011c74e20", "https://admin.roardigital.co.uk");
  if (!data) return { data: null, status, ENDPOINT_STATUSES, refresh };
  return { data, status, ENDPOINT_STATUSES, refresh };
}

export {
  ENDPOINT_STATUSES,
};
