import React from 'react';
import {Card, CardProps} from "../../components";
import { ClientAccount } from '../hooks/useClientAccounts';
import useMonthlyReviews, { MonthlyReview, useLatestMonthlyReview } from '../hooks/useMonthlyReviews';
import { Link }from "react-router-dom";

const Months = ["January","February","March","April","May","June","July","August","September","October","November","December"];

export interface LatestReviewCardProps extends CardProps {
  currentAccount: ClientAccount | null,
  monthlyReviews: ReturnType<typeof useMonthlyReviews>,
}

export default function LatestReviewCard({currentAccount, monthlyReviews}:LatestReviewCardProps) {
  const latestReview = useLatestMonthlyReview(monthlyReviews);
  const month = latestReview?.data.attributes.for_month;
  const year = latestReview?.data.attributes.for_year;
  const accountSlug = currentAccount?.data.attributes.slug;
  const showPrintLink = accountSlug && year && month;
  return (
    <Card >
      <Card.Row>
        <Card.Title>{latestReview ? Months[latestReview.data.attributes.for_month - 1] : "Monthly"} Report </Card.Title>
        { showPrintLink && <Link className="mb-3 block text-gray-500 hover:text-gray-800 transition-colors hover:underline text-sm" to={`/${accountSlug}/${year}/${month}/print`} title="Print this report">Print</Link> } 
      </Card.Row>
      <LatestReviewCardView latestReview={latestReview} monthlyReviews={monthlyReviews} accountName={currentAccount?.data.attributes.name}/>
    </Card>
  );
}

interface LatestReviewCardViewProps {
  latestReview: MonthlyReview | null,
  monthlyReviews: ReturnType<typeof useMonthlyReviews>,
  accountName?: string,
}

function LatestReviewCardView({latestReview, monthlyReviews, accountName}:LatestReviewCardViewProps) : JSX.Element {
  const {ERROR, SUCCESS} = monthlyReviews.ENDPOINT_STATUSES;
  const {status, data} = monthlyReviews;

  if ( status === ERROR ) return  <div className="mt-3 mb-2">An error occurred. Please try again later.</div>;
  if ( status !== SUCCESS ) return <Card.Loading />;
  if ( data && data.length === 0 && status === SUCCESS ) return <div className="mt-3 mb-2">No reports available</div>;
  if (!latestReview) return <div className="mt-3 mb-2">An unexpected error occurred. Please try again later.</div>

  return (
    <div className="report-wrapper" dangerouslySetInnerHTML={{ __html: latestReview ? latestReview.data.attributes.content : "" }}></div>
  );       
}