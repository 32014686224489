import React from 'react'

export interface CardWrapperProps {
  children?: React.ReactNode,
}

function hasOwnProperty<X extends {}, Y extends PropertyKey>
  (obj: X, prop: Y): obj is X & Record<Y, unknown> {
  return obj.hasOwnProperty(prop)
}

export default function CardWrapper ({children}: CardWrapperProps) {
  const sidebar = React.Children.toArray(children).filter(child => {
    if(typeof child !== "object") return false;
    if(hasOwnProperty(child, "props") && child.props.sidebar ) return true;
    return false;
  });
  const main = React.Children.toArray(children).map(child => {
    if(typeof child === "object" && hasOwnProperty(child, "props") && child.props.sidebar ) {
      return <div className="lg:hidden">{child}</div>
    };
    return child;
  });
  return (
    <div className="md:px-16 px-4 pb-8 flex m-auto max-w-screen-xl items-start">
      <div className={`grid gap-4 grid-cols-1  w-full lg:w-3/4 lg:pr-4`}>
        {main}
      </div>
      <div className="grid gap-4 grid-cols-1 w-1/4 hidden lg:grid min-width-280">
        {sidebar}
      </div>
    </div>
  );
}