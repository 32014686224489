import React, {useState, useEffect} from 'react';
import { match, useHistory } from 'react-router-dom';
import "./Print.scss";
import {useMonthlyReview, MonthlyReview} from "../hooks/useMonthlyReviews";
import {useMonthlyRecommendation, MonthlyRecommendation} from "../hooks/useMonthlyRecommendations";
import {ClientAccount, useClientAccount} from '../hooks/useClientAccounts';
import { Helmet } from "react-helmet";
import {ENDPOINT_STATUSES} from "ssomg-react";

const { SUCCESS, ERROR, LOADING } = ENDPOINT_STATUSES;
const Months = ["January","February","March","April","May","June","July","August","September","October","November","December"];

function toMonth(s:string) {
  const n = Number.parseInt(s);
  if (isNaN(n)) return s;
  if ( n >= 1 && n <= 12 ) return Months[n-1];
  return s;
}

type PrintParams = {
  client: string,
  year: string,
  month: string,
}

const usePaidSearchReports = (client: string, year:string, month:string) => {
  const {account:clientAccount, status: accountStatus} = useClientAccount(client);
  const accId = clientAccount ? clientAccount.data.id : null;
  const {review, status: reviewStatus} = useMonthlyReview(accId, year, month);
  const {recommendation, status: recommendationStatus} = useMonthlyRecommendation(accId, year, month);
  const [status, setStatus] = useState<number>(ENDPOINT_STATUSES.LOADING);

  const allSuccess = accountStatus === SUCCESS && reviewStatus === SUCCESS && recommendationStatus === SUCCESS;
  const hasReviewOrRec = clientAccount && ( review || recommendation);
  useEffect(()=>{
    if (allSuccess) {
      if (hasReviewOrRec) {
        setStatus(SUCCESS);
      } else {
        setStatus(ERROR);
      }
    }
  },[hasReviewOrRec, allSuccess]);

  return {review, recommendation, clientAccount, status, ENDPOINT_STATUSES};
}

export const Print : React.FC<{match: match<PrintParams>}> = ({match}) => {
  const { params } = match;
  const history = useHistory();
  const {review, recommendation, clientAccount, status} = usePaidSearchReports(params.client, params.year, params.month);
  useEffect(() => {
    if(clientAccount && status === SUCCESS) {
      const timeout = setTimeout(() => {
        window.onafterprint = function() {
          history.push(`/${params.client}/${params.year}/${params.month}`)
        }
        window.print();
      }, 500);
      return () => {
        clearTimeout(timeout);
      }
    }
  });
  if (clientAccount && status === SUCCESS) {
    return <PrintView monthlyReview={review} monthlyRecommendation={recommendation} clientAccount={clientAccount} year={params.year} month={params.month}/>;
  } else if (status === ERROR) {
    return <div>An error occurred. Please try again later.</div>
  } else if (status === LOADING) {
    return <div className="loading-screen"></div>;
  }
  return null;
}

interface PrintViewProps {
  monthlyReview: MonthlyReview | null, 
  monthlyRecommendation: MonthlyRecommendation | null, 
  clientAccount: ClientAccount,
  year: string,
  month: string,
}

const PrintView = React.memo(({monthlyReview, monthlyRecommendation, clientAccount, month, year}: PrintViewProps)  => {
  return <div className="page">
    <Helmet>
      <title>Roar Report - {clientAccount.data.attributes.name} - {toMonth(month)}/{year}</title>
      <meta name="author" content="Roar Digital LTD" />
      <meta name="keywords" content="PPC Report" />
    </Helmet>
    <div className="header">
      <img className="logo" src="/logo-dark.png" alt="Roar logo"/>
      <p>{toMonth(month)} {year}</p>
    </div>
    <div className="title">
      <h1>{clientAccount.data.attributes.name} - Paid Search Report</h1>
    </div>
    {monthlyReview ? (
      <div className="section" >
        <div className="sectiontitle">
          <h2>Account Overview</h2>
          <p className="lastupdated">Last updated {new Date(monthlyReview.data.attributes.updated_at).toLocaleDateString()} by {monthlyReview.data.attributes.author}</p>
        </div>
        <div className="report-wrapper" dangerouslySetInnerHTML={{ __html: monthlyReview ? monthlyReview.data.attributes.content : "" }}></div>
      </div>
    ) : undefined
    }
    {monthlyRecommendation ? (
      <div className="section">
        <div className="sectiontitle">
          <h2>Recommendations</h2>
          <p className="lastupdated">Last updated {new Date(monthlyRecommendation.data.attributes.updated_at).toLocaleDateString()} by {monthlyRecommendation.data.attributes.author}</p>
        </div>
        <div className="report-wrapper" dangerouslySetInnerHTML={{ __html: monthlyRecommendation ? monthlyRecommendation.data.attributes.content : "" }}></div>
      </div>
    ) : undefined
    }
  </div>
});