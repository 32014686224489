import React from 'react'
import {Card, CardProps, Button} from "../../components";

export interface ReferralCardProps extends CardProps {
}

export default function ReferralCard ({children}: ReferralCardProps) {
  return (
    <Card>
      <Card.Subtitle>Referrals</Card.Subtitle>
      <h2 className="text-xl font-semibold text-blue-roar my-6">Get lower rates when someone you refer signs up</h2>
      <div className="flex justify-between items-center">
        <a href="mailto:jack.mills@roardigital.co.uk?subject=Referral%20Scheme%20Enquiry" className="text-gray-400 font-semibold text-sm">Find out more</a>
        <Button href="mailto:jack.mills@roardigital.co.uk?subject=Roar%20Referral">Start</Button>
      </div>
    </Card>
  );
}
