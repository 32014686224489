import React from 'react'

// todo - make pretty, add nice message saying to refresh if they think this is an error.
export default function LoggedOutView() {
  return (
    <div className="flex justify-center items-center bg-gray-roar w-full min-h-screen flex-col text-raleway">
      <h1 className="text-open-sans font-semibold text-7xl text-blue-roar">Error 403</h1>
      <hr className="w-96 border-t-2 border-yellow-roar mt-4 mb-3"></hr>
      <p className="max-w-sm w-full p-1 text-center">You do not have permission to access the requested page.</p>
      <p className="max-w-sm w-full p-1 text-center">If you normally have access, refresh the page to try authenticating again. Please email your account manager if you believe this is an error.</p>
    </div>
  )
}
