import React from 'react'
import { Link } from "react-router-dom";


/**
 * Generates a nav link. Only pass one of "to", "href", or "onClick". Any extra props will be applied to the element.
 *
 * @interface NavItemProps
 */
export interface NavItemProps {
  children?: React.ReactNode,
  to?: string,
  href?: string,
  className?: string
  onClick?: React.MouseEventHandler<HTMLButtonElement>,
  active?: boolean,
  strictActiveCheck?: boolean,
  inactiveClasses?:string,
  activeClasses?:string,
  [prop:string]: any
}

export default function NavItem ({children, className, to, onClick, href, active, strictActiveCheck=false, activeClasses, inactiveClasses, ...rest }: NavItemProps) {
  let isActive = active;
  if ( typeof isActive === "undefined" ) {
    if (!to) {
      isActive = false;
    } else {
      if (strictActiveCheck) {
        isActive = window.location.pathname === to;
      } else {
        isActive = window.location.pathname.indexOf(to) === 0
      }
    }
  } 

  const defaultActiveClasses = "";
  const defaultInactiveClasses = "opacity-70 hover:opacity-100 transition-opacity";
  const defaultClasses = "ml-10 text-lg font-semibold";

  const customInactiveClasses = inactiveClasses ? inactiveClasses : defaultInactiveClasses;
  const customActiveClasses = activeClasses ? activeClasses : defaultActiveClasses;
  const stateClasses = isActive ? customActiveClasses : customInactiveClasses; 
  const customClasses = (className ? className : defaultClasses) + " " + stateClasses;

  if (href) {
    return <a href={href} className={customClasses} {...rest}>{children}</a>;
  } else if (to) {
    return <Link to={to} className={customClasses} {...rest}>{children}</Link>;
  } else if (onClick) {
    return <button onClick={onClick} className={customClasses} {...rest}>{children}</button>
  } else {
    throw new Error("Nav item must have one of href, to, or onClick props")
  }
}
