import React from 'react'

const Main: React.FC = ({ children }) =>  {
  return (
    <div className="bg-gray-roar min-h-screen text-raleway">
      {children}
    </div>
  )
}

export default Main;
