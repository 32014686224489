import React from 'react'

export interface HeroProps {
  children?: React.ReactNode,
}

export default function Hero({children} : HeroProps) {
  return (
    <header className="bg-blue-roar text-white text-raleway py-6 px-4 md:py-8 md:px-16 -mb-28">
      <div className="max-w-screen-xl m-auto">
        {children}
      </div>
    </header>
  )
}
export interface Children {
  children: React.ReactNode,
}
Hero.Title = ({children} : Children) => {
  return (
    <h1 className="text-3xl md:text-5xl font-semibold">{children}</h1>
  );
}

Hero.Subtitle = ({children} : Children) => {
  return (
    <p className="md:text-lg mt-6 font-semibold">{children}</p>
  );
}

Hero.Main = ({children} : Children) => {
  return (
    <div className="mt-16 mb-32 md:px-8">{children}</div>  
  );
}
