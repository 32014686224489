import React from 'react'
import {Link} from 'react-router-dom';

export interface ButtonProps {
  children?: React.ReactNode,
  className?: string,
  href?: string,
  to?: string,
  onClick?: React.MouseEventHandler<HTMLButtonElement>,
  [k:string]: any
}

export default function Button ({children, className="", to, href, onClick, ...rest }: ButtonProps) {
  const classes = "font-bold text-blue-roar bg-yellow-roar py-2.5 px-4 rounded text-center " + className;
  if (href) {
    return <a href={href} className={classes} {...rest}>{children}</a>;
  } else if (to) {
    return <Link to={to} className={classes} {...rest}>{children}</Link>;
  } else if (onClick) {
    return <button onClick={onClick} className={classes} {...rest}>{children}</button>
  } else {
    throw new Error("Nav item must have one of href, to, or onClick props")
  }
}
