import React from 'react';
import {Card, CardProps} from "../../components";
import useMonthlyRecommendations, { MonthlyRecommendation, useLatestMonthlyRecommendation } from '../hooks/useMonthlyRecommendations';

export interface LatestRecommendationCardProps extends CardProps {
  monthlyRecommendations: ReturnType<typeof useMonthlyRecommendations>
}

export default function LatestRecommendationCard({monthlyRecommendations}:LatestRecommendationCardProps) {
  const latestRecommendation = useLatestMonthlyRecommendation(monthlyRecommendations);

  return (
    <Card>
      <Card.Title>Our next steps</Card.Title>
      <LatestRecommendationCardView latestRecommendation={latestRecommendation} monthlyRecommendations={monthlyRecommendations}/>
    </Card>
  );
}

interface LatestRecommendationCardViewProps {
  latestRecommendation: MonthlyRecommendation | null,
  monthlyRecommendations: ReturnType<typeof useMonthlyRecommendations>,
}

function LatestRecommendationCardView({latestRecommendation, monthlyRecommendations}:LatestRecommendationCardViewProps) : JSX.Element {
  const {ERROR, SUCCESS} = monthlyRecommendations.ENDPOINT_STATUSES;
  const {status, data} = monthlyRecommendations;

  if ( status === ERROR ) return  <div className="mt-3 mb-2">An error occurred. Please try again later.</div>;
  if ( status !== SUCCESS ) return <Card.Loading />;
  if ( data && data.length === 0 && status === SUCCESS ) return <div className="mt-3 mb-2">No recommendations available</div>;
  if (!latestRecommendation) return <div className="mt-3 mb-2">An unexpected error occurred. Please try again later.</div>

  return (
    <div className="report-wrapper" dangerouslySetInnerHTML={{ __html: latestRecommendation ? latestRecommendation.data.attributes.content : "" }}></div>
  );       
}