import React from "react";

export interface CardProps { 
  children?: React.ReactNode,
  sidebar?:boolean,
  className?:string,
}

export default function Card({children, sidebar=false, className=""}: CardProps ) {
  return (
    <article className={`py-5 px-6 col-span-1 bg-white shadow rounded-xl relative ${className} w-full`}>
      {children}
    </article>
  )
}

Card.Title = function({children}: {children: React.ReactNode}) {
  return <h1 className="text-2xl font-bold text-blue-roar mb-3">{children}</h1>
}

Card.Row = function({children}: {children: React.ReactNode}) {
  return <div className="flex items-center justify-between">{children}</div>
}
Card.Subtitle = function({children}: {children: React.ReactNode}) {
  return <h1 className="text-xl font-semibold text-blue-roar underline mt-1">{children}</h1>
}

Card.Loading = function({children,}: CardProps ) {
  return (
    <div className={`h-16 w-full relative loading-card`}></div>
  )
}