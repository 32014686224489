import React from 'react';
import { BrowserRouter, Switch } from "react-router-dom";
import ReactDOM from 'react-dom';
import './index.css';
import "typeface-raleway";
import "typeface-open-sans";
import App from './App';
import { Print } from "./localComponents/pages/Print";
import reportWebVitals from './reportWebVitals';
import { UserProvider } from "ssomg-react"
import "typeface-raleway";
import "typeface-open-sans";
import {ProtectedRoute} from "./components";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <UserProvider>
        <Switch>
          <ProtectedRoute roles={["access"]} blankDefaults={true} path="/:client([a-z0-9-]+)/:year(\d{4})/:month(\d{1,2})/print" component={Print}/>
          <ProtectedRoute roles={["access"]} component={App} />
        </Switch>
      </UserProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
