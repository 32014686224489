import React, { useContext } from 'react'
import { UserContext } from "ssomg-react"
import { Route, RouteProps } from "react-router-dom";
import LoadingView from "./LoadingView";
import LoggedOutView from "./LoggedOutView";
import RedirectingView from "./RedirectingView";
import Blank from "./Blank";
export interface ProtectedRouteProps extends RouteProps {
  [key:string]: any,
  roles?: string[]
  blankDefaults?:boolean
}

// todo: render props for each view
export default function ProtectedRoute({
  component: Component,
  children,
  blankDefaults=false,
  roles,
  ...rest
} : ProtectedRouteProps ) {
  const { status, USER_STATUSES, user } = useContext(UserContext); 
  const {STARTING, LOGGED_OUT, EXTERNAL_REDIRECT} = USER_STATUSES
  if ( status === STARTING ) {
    return <Route {...rest} component={blankDefaults ? Blank : LoadingView} />;
  } 
  if ( status === LOGGED_OUT ) {
    return <Route {...rest} component={blankDefaults ? Blank : LoggedOutView} />;
  }
  if ( status === EXTERNAL_REDIRECT ) {
    return <Route {...rest} component={blankDefaults ? Blank : RedirectingView} />;
  } 
  // now must be logged in 
  if (!user) {
    // this should never happen
    return <Route {...rest} component={blankDefaults ? Blank : LoggedOutView} />;
  }
  if ( roles ) {
    let permitted = false;
    for (let role of roles) {
      if (user.roles.includes(role)) {
        permitted = true;
        break;
      }
    }
    if (!permitted) return <Route {...rest} component={blankDefaults ? Blank : LoggedOutView} />;
  }
  
  if (Component) {
    return <Route {...rest} component={Component}/>
  }
  return (
    <Route {...rest}>
      {children}
    </Route>
  )
}
