import React, {useState, useEffect} from 'react'
import useProductsAndServices, {EarthProducts, EarthServices} from "../hooks/useProductsAndServices";
import { ClientAccount } from '../hooks/useClientAccounts';
import {Card, CardProps} from "../../components";

interface ServiceView {
  name: string,
  id: string,
  enabled: boolean,
  category: string
}

interface ProductView {
  name: string,
  id: string, 
  enabled: boolean,
  platforms: ServiceView[],
  services: ServiceView[]
};

export interface ProductsCardProps extends CardProps {
  currentAccount: ClientAccount | null,
}
function clientHasProduct(id:string, products: EarthProducts, currentAccount: ClientAccount) {
  if (!products.data || !currentAccount) return false;
  for (let prod of currentAccount.data.attributes.products) {
    if (`${prod.id}` === `${id}` )return true;
  }
  return false;
}

function clientHasService(id:string, services: EarthServices, currentAccount: ClientAccount) {
  if (!services.data || !currentAccount) return false;
  for (let prod of currentAccount.data.attributes.services) {
    if (`${prod.id}` === `${id}`)return true;
  }
  return false;
}

function getServiceById(id:string, services: EarthServices) {
  if (!services.data) return null;
  for (let prod of services.data) {
    if (`${prod.data.id}` === `${id}` )return prod;
  }
  return null;
}

export default function ProductsCard ({currentAccount}: ProductsCardProps) {
  const {products, services} = useProductsAndServices(!!currentAccount);
  const [subbedProducts, setSubbedProducts] = useState<ProductView[]>([]);

  useEffect(() => {
    if (products.data && services.data && currentAccount ) {
      const prods : ProductView[] = products.data
      .map( (prod) => {
        const groupedServices: ServiceView[] = [];
        const groupedPlatforms: ServiceView[] = [];
        prod.data.attributes.services
          .forEach(s=>{
            const service = getServiceById(s.id, services);
            if (!service) return null;
            const formattedService = {
              name: service.data.attributes.name,
              id: service.data.id,
              enabled: clientHasService(service.data.id, services, currentAccount),
              category: service.data.attributes.category,
            };
            if (formattedService.category === "platforms") {
              groupedPlatforms.push(formattedService);
            } else {
              groupedServices.push(formattedService);
            }
          });
        return {
          name: prod.data.attributes.name,
          id: prod.data.id,
          enabled: clientHasProduct(prod.data.id, products, currentAccount),
          services: groupedServices,
          platforms: groupedPlatforms,
        }
      });
      setSubbedProducts(prods);
    } 
  // status change indicates the data has changed, and as it is an integer it is cheap to compare
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccount, products.status, services.status]);
  
  return (
    <Card>
      <Card.Subtitle>Products</Card.Subtitle>
      <ProductsCardView currentAccount={currentAccount} subbedProducts={subbedProducts}/>
    </Card>
  );
}

export interface ProductsCardViewProps extends CardProps {
  currentAccount: ClientAccount | null,
  subbedProducts: ProductView[]
}

function Pill({name, enabled}: {name:string, enabled: boolean}) {
  const enabledClasses = "bg-green-700 text-green-100 rounded py-1 px-2 text-sm text-center";
  const disabledClasses = "bg-gray-400 text-gray-100 rounded py-1 px-2 text-sm text-center";
  return <div key={name} className={enabled? enabledClasses : disabledClasses}>{name}</div>
}

function ProductsCardView({currentAccount, subbedProducts}:ProductsCardViewProps) {
  if (!currentAccount || subbedProducts.length === 0) return <Card.Loading />
  return <>
  {subbedProducts.map((prod, i)=> 
    <div key={prod.name}>
      <div className="flex justify-between items-center mt-3 ">
        <h2 className="text-lg font-semibold text-blue-roar">{prod.name}</h2>
        <Pill name={prod.enabled ? "Active" : "Inactive"} enabled={prod.enabled}/>
      </div>
      {prod.platforms.length > 0 ? <>
        <h3 className="text-sm font-semibold text-blue-roar mt-3 mb-1.5">Platforms</h3> 
        <div className="grid grid-cols-2 gap-1">
          {prod.platforms.map(Pill)}
        </div>
      </> : null
      }
      {prod.services.length > 0 ? <> 
        <h3 className="text-sm font-semibold text-blue-roar mt-3 mb-1.5">Services</h3> 
        <div className="grid grid-cols-2 gap-1">
          {prod.services.map(Pill)}
        </div>
      </> : null}
      {i !== subbedProducts.length - 1 ? <hr className="mt-3"/> : null}
    </div>
  )}
  </>;
}