import {useState, useEffect} from "react";
import useEarth, {EarthResource, ENDPOINT_STATUSES} from "./useEarth";

export interface MonthlyReviewAttributes {
    author: string
    client_account_id: number
    content: string
    created_at: string // date string
    for_month: number
    for_year: number
    id: number
    product_id: number
    updated_at: string // date string
}


export type MonthlyReview = EarthResource<MonthlyReviewAttributes>;

export default function useMonthlyReviews(accountId?: string | null) {
  const path = accountId ? `/api3/client-accounts/${accountId}/monthly-reviews` : undefined;
  return useEarth<MonthlyReview[]>(path);
}

export function useMonthlyReview(accountId: string | null, year:string | number, month:string | number) {
  const {data, status, ENDPOINT_STATUSES, refresh } = useMonthlyReviews(accountId);
  const [review, setReview] = useState<MonthlyReview | null>(null);
  const [reviewStatus, setReviewStatus] = useState<number>(ENDPOINT_STATUSES.LOADING);
  useEffect(()=>{
    if (!data) return;
    data.forEach((report) => {
      const { for_year, for_month } = report.data.attributes;
      if (`${for_year}` === year && `${for_month}` === month) {
        setReview(report)
      };
    });
    setReviewStatus(ENDPOINT_STATUSES.SUCCESS);
  },[data, month, year, ENDPOINT_STATUSES]);

  useEffect(()=>{
    setReview(null);
  },[accountId]);

  useEffect(()=>{
    if (status !== ENDPOINT_STATUSES.SUCCESS) {
      setReviewStatus(status);
    }
  },[status, ENDPOINT_STATUSES]);
  return {review, status: reviewStatus, ENDPOINT_STATUSES, refresh }
}

export function useLatestMonthlyReview( monthlyReviews: ReturnType<typeof useMonthlyReviews>) {
  const [latestReview, setLatestReview] = useState<MonthlyReview | null>(monthlyReviews.data && monthlyReviews.data[monthlyReviews.data.length - 1])
  const {SUCCESS} = monthlyReviews.ENDPOINT_STATUSES;
  const {status, data} = monthlyReviews;

  
  useEffect(()=>{
    if ( status !== SUCCESS || !data ) {
      setLatestReview(null);
    } else {
      setLatestReview(data[data.length - 1]);
    }
  },[status, data, setLatestReview, SUCCESS]);

  return latestReview;
}

export {
  ENDPOINT_STATUSES,
}