import React, {useContext}  from 'react';
import './App.scss';

import Home from "./localComponents/pages/Home";
import {Main, Hero, NavBar, NavItem} from "./components";
import { UserContext } from "ssomg-react"
import useClientAccounts from './localComponents/hooks/useClientAccounts';
function App() {
  const { user } = useContext(UserContext); 
  const { allAccounts, currentAccount, goToAccount, status } = useClientAccounts();
  return (
    <Main>
      <Hero>
        <NavBar>
          {/* <NavItem to="/">Dashboard</NavItem> */}
          {currentAccount && currentAccount.data.attributes.dashboard_url && 
            <NavItem href={currentAccount?.data.attributes.dashboard_url} target="_blank" rel="noreferrer" className="text-sm md:text-lg font-semibold ml-3 py-0.5 px-3 rounded-3xl border-2">Account Data</NavItem>
          }
        </NavBar>
        <Hero.Main>
          <Hero.Title>{user && `Hi, ${user.first_name}`}</Hero.Title>
          <Hero.Subtitle>Welcome back. Here are a few things for you to look at.</Hero.Subtitle>
        </Hero.Main>
      </Hero>
      <Home allAccounts={allAccounts} currentAccount={currentAccount} goToAccount={goToAccount} status={status}/>
    </Main>
  );
}

export default App;
