import React from 'react';
import { useRouteMatch } from "react-router-dom";
import useClientAccounts from '../hooks/useClientAccounts';
import {CardWrapper} from "../../components";

import LatestReviewCard from "../partials/LatestReviewCard";
import ReviewCard from "../partials/ReviewCard";
import RecommendationCard from "../partials/RecommendationCard";
import LatestRecommendationCard from "../partials/LatestRecommendationCard";
import AccountSwitcherCard from "../partials/AccountSwitcherCard";
import ArchiveCard from "../partials/ArchiveCard";
import AccountManagerCard from "../partials/AccountManagerCard";
import ReferralCard from "../partials/ReferralCard";
import ProductsCard from "../partials/ProductsCard";

import useMonthlyReviews from "../hooks/useMonthlyReviews";
import useMonthlyRecommendations from "../hooks/useMonthlyRecommendations";

type HomeProps = ReturnType<typeof useClientAccounts>

export default function Home({ allAccounts, currentAccount, goToAccount, status }:HomeProps) {
  const monthlyRecommendations = useMonthlyRecommendations(currentAccount && currentAccount.data.id);
  const monthlyReviews = useMonthlyReviews(currentAccount && currentAccount.data.id);
  const match = useRouteMatch<{account: string, year: string, month: string}>("/:account?/:year?/:month?");
  const year = match?.params.year;
  const month = match?.params.month;
  const accountID = currentAccount?.data.id;
  function onSelect(event: React.ChangeEvent<HTMLSelectElement>) {
    const newSelected = allAccounts?.filter((el) => el.data.id === event.target.value);
    if (newSelected && newSelected.length === 1) {
      goToAccount(newSelected[0]);
    }
  }
  if (!allAccounts || !monthlyRecommendations || !monthlyReviews ) return <></>;
  return (
    <CardWrapper>
      <AccountSwitcherCard sidebar={true} currentAccount={currentAccount} allAccounts={allAccounts} onSelect={onSelect}/>
      { month && year && currentAccount ? 
          <ReviewCard key={`${accountID}-${year}-${month}-review`} account={currentAccount} month={month} year={year} />
        :
          <LatestReviewCard key={`latest-review`} currentAccount={currentAccount} monthlyReviews={monthlyReviews} />
      }
      { month && year && currentAccount ? 
          <RecommendationCard key={`${accountID}-${year}-${month}-recommendation`}  account={currentAccount} month={month} year={year} />
        :
          <LatestRecommendationCard key={`latest-recommendation`} monthlyRecommendations={monthlyRecommendations}/>
      }
      <ProductsCard currentAccount={currentAccount} sidebar={true}/>
      <AccountManagerCard sidebar={true} monthlyReviews={monthlyReviews} />
      <ReferralCard sidebar={true} />
      <ArchiveCard sidebar={true} currentAccount={currentAccount} monthlyReviews={monthlyReviews} />
    </CardWrapper>
  );
}