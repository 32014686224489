import React, { useState, useEffect, useContext } from 'react'
import Dropdown from '../../Utils/Dropdown.js';
import { UserContext } from "ssomg-react"
import md5 from 'md5';

const Avatar = ({src, alt, children}: {src: null|string, alt?:string, children: React.ReactNode}) => {
  return (
    <div className="w-10 h-10 ml-5 relative">
      <div className="group w-full h-full rounded-full overflow-hidden shadow-inner text-center bg-gray-400 table cursor-pointer">
        <span className="text-white font-bold align-middle absolute w-full l-0 top-1.5 left-0 text-lg">{children}</span>
        {src && <img src={src} alt={alt} className="object-cover object-center w-full h-full visible group-hover:hidden" />}
      </div>
    </div>
  );
}

const UserDropdown = () => {
  const { logout, user, refresh } = useContext(UserContext);
  const [gravUrl, setGravUrl] = useState<null | string>(null);
  useEffect(()=>{
    if ( user ) {
      setGravUrl(`https://www.gravatar.com/avatar/${md5(user.email.toLowerCase().trim())}?d=blank`);
    }
  },[user]);
  if ( !user ) return null; 
  return (
    <Dropdown title={
      <Avatar src={gravUrl} alt={user.first_name}>{user.first_name.charAt(0)}</Avatar>
    }
    className="origin-top-right absolute top-full right-0 bg-white py-2 ml-4 rounded shadow z-10">
      <li className="flex">
        <span className="ml-auto font-semibold text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">
          {user.first_name} {user.last_name}            
        </span>      
      </li>
      <li className="flex">
        <span className="ml-auto font-semibold text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">
          {user.email}            
        </span>      
      </li>
      <li className="flex">
        <a href="https://auth.roardigital.co.uk" target="_blank" rel="noreferrer" className="ml-auto text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">
          <span>Profile</span>               
        </a>      
      </li>
      <li>
        <hr/>
      </li>
      <li className="flex">
        <button title="If your permission have changed, you can refresh the session to update them." onClick={refresh} className="ml-auto text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">
          <span>Refresh session</span>               
        </button>      
      </li>
      <li className="flex">
        <button onClick={logout} className="ml-auto text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">
          <span>Logout</span>               
        </button>      
      </li>
    </Dropdown>
  )
}
export default UserDropdown;