import {useState, useEffect} from "react";
import useEarth, {EarthResource, ENDPOINT_STATUSES} from "./useEarth";

export interface MonthlyRecommendationAttributes {
    author: string
    client_account_id: number
    content: string
    created_at: string // date string
    for_month: number
    for_year: number
    id: number
    product_id: number
    updated_at: string // date string
}


export type MonthlyRecommendation = EarthResource<MonthlyRecommendationAttributes>;

export default function useMonthlyRecommendations(accountId?: string | null) {
  const path = accountId ? `/api3/client-accounts/${accountId}/monthly-recommendations` : undefined;
  return useEarth<MonthlyRecommendation[]>(path);
}

export function useMonthlyRecommendation(accountId: string | null, year:string | number, month:string | number) {
  const {data, status, ENDPOINT_STATUSES, refresh } = useMonthlyRecommendations(accountId);
  const [recommendation, setRecommendation] = useState<MonthlyRecommendation | null>(null);
  const [recommendationStatus, setRecommendationStatus] = useState<number>(ENDPOINT_STATUSES.LOADING);
  useEffect(()=>{
    if (!data) return;
    data.forEach((report) => {
      const { for_year, for_month } = report.data.attributes;
      if (`${for_year}` === year && `${for_month}` === month) {
        setRecommendation(report)
      };
    });
    setRecommendationStatus(ENDPOINT_STATUSES.SUCCESS);
  },[data, month, year, ENDPOINT_STATUSES]);

  useEffect(()=>{
    setRecommendation(null);
  },[accountId]);

  useEffect(()=>{
    if (status !== ENDPOINT_STATUSES.SUCCESS) {
      setRecommendationStatus(status);
    }
  },[status, ENDPOINT_STATUSES]);
  return {recommendation, status: recommendationStatus, ENDPOINT_STATUSES, refresh }
}



export function useLatestMonthlyRecommendation( monthlyRecommendations: ReturnType<typeof useMonthlyRecommendations>) {
  const [latestRecommendation, setLatestRecommendation] = useState<MonthlyRecommendation | null>(monthlyRecommendations.data && monthlyRecommendations.data[monthlyRecommendations.data.length - 1])
  const {SUCCESS} = monthlyRecommendations.ENDPOINT_STATUSES;
  const {status, data} = monthlyRecommendations;

  
  useEffect(()=>{
    if ( status !== SUCCESS || !data ) {
      setLatestRecommendation(null);
    } else {
      setLatestRecommendation(data[data.length - 1]);
    }
  },[status, data, setLatestRecommendation, SUCCESS]);

  return latestRecommendation;
}

export { 
  ENDPOINT_STATUSES,
}