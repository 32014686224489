import React from 'react'
import UserDropdown from "../UserDropdown";
import { Link } from "react-router-dom";

export interface NavBarProps {
  children?: React.ReactNode,
}

export default function NavBar({children}: NavBarProps) {
  // christmas logo in december
  const logo = (new Date()).getMonth() === 11 ? "/logo-xmas.png" : "/logo-pearl.png";
  return (
    <nav className="flex justify-between items-center">
      <Link className="mr-auto" to="/"><img className="logo" src={logo} alt="Logo" /></Link>
      {children}
      <UserDropdown />
    </nav>
  );
}
