import React from 'react';
import {Card, CardProps} from "../../components";
import { ClientAccount } from '../hooks/useClientAccounts';
import { MonthlyRecommendation, useMonthlyRecommendation, ENDPOINT_STATUSES } from '../hooks/useMonthlyRecommendations';

export interface RecommendationCardProps extends CardProps {
  month: number | string,
  year: number | string,
  account: ClientAccount,
}

export default function RecommendationCard({account, month, year}:RecommendationCardProps) {
  const accountID = account.data.id;
  const {recommendation, status} = useMonthlyRecommendation(accountID, year, month);
  
  return (
    <Card>
      <Card.Title>Our next steps</Card.Title>
      <RecommendationCardView recommendation={recommendation} status={status}/>
    </Card>
  );
}

interface RecommendationCardViewProps {
  recommendation: MonthlyRecommendation | null,
  status: number,
}

function RecommendationCardView({recommendation, status}:RecommendationCardViewProps) : JSX.Element {
  const {ERROR, SUCCESS} = ENDPOINT_STATUSES;

  if ( status === ERROR ) return  <div className="mt-3 mb-2">An error occurred. Please try again later.</div>;
  if ( status !== SUCCESS ) return <Card.Loading />;
  if ( !recommendation && status === SUCCESS ) return <div className="mt-3 mb-2">No recommendations available for this period</div>;
  if (!recommendation) return <div className="mt-3 mb-2">An unexpected error occurred. Please try again later.</div>

  return (
    <div className="report-wrapper" dangerouslySetInnerHTML={{ __html: recommendation ? recommendation.data.attributes.content : "" }}></div>
  );       
}