import useEarth, {EarthResource, UseEarthReturns} from "./useEarth";

export interface ProductAttributes {
  client_accounts: {
    id: string,
    type: string
  }[],
  icon_active: string,
  icon_inactive: string,
  id: number,
  name: string,
  services: {
    id: string,
    type: string
  }[],
  slug: string
}

export interface ServiceAttributes {
  category: string,
  client_accounts: {
    id: string,
    type: string
  }[],
  icon_active: string,
  icon_inactive: string,
  id: number,
  name: string,
  product_id: string
}


export type Product = EarthResource<ProductAttributes>;
export type Service = EarthResource<ServiceAttributes>;
export type EarthProducts = UseEarthReturns<Product[]>;
export type EarthServices = UseEarthReturns<Service[]>;
export default function UseProductsAndServices(start=false) {
  const products = useEarth<Product[]>(start ? `/api3/products` : undefined);
  const services = useEarth<Service[]>(start ? `/api3/services` : undefined);
  return {products, services}
}
