import React from 'react';
import {Card, CardProps} from "../../components";
import { MonthlyReview, useMonthlyReview, ENDPOINT_STATUSES } from '../hooks/useMonthlyReviews';
import { Link }from "react-router-dom";
import { ClientAccount } from '../hooks/useClientAccounts';

const Months = ["January","February","March","April","May","June","July","August","September","October","November","December"];

export interface ReviewCardProps extends CardProps {
  month: number | string,
  year: number | string,
  account: ClientAccount,
}

export default function ReviewCard({account, month, year}:ReviewCardProps) {
  const accountID = account.data.id;
  const {review, status} = useMonthlyReview(accountID, year, month);
  const mm = review ? Months[review.data.attributes.for_month - 1] : "";
  const yy = review ? review.data.attributes.for_year : "";
  const accountSlug = account.data.attributes.slug;
  const showPrintLink = accountSlug && year && month;

  return (
    <Card >
      <Card.Row>
        <Card.Title>Monthly Report {mm && yy && '-'} {mm} {yy}</Card.Title>
        {showPrintLink && <Link className="mb-3 block text-gray-500 hover:text-gray-800 transition-colors hover:underline text-sm" to={`/${accountSlug}/${year}/${month}/print`} title="Print this report">Print</Link>} 
      </Card.Row>
      <ReviewCardView review={review} status={status}/>
    </Card>
  );
}

interface ReviewCardViewProps {
  review: MonthlyReview | null,
  status: number,
}

function ReviewCardView({review, status}:ReviewCardViewProps) : JSX.Element {
  const {ERROR, SUCCESS} = ENDPOINT_STATUSES;

  if ( status === ERROR ) return  <div className="mt-3 mb-2">An error occurred. Please try again later.</div>;
  if ( status !== SUCCESS ) return <Card.Loading />;
  if ( !review && status === SUCCESS ) return <div className="mt-3 mb-2">No report available for this period</div>;
  if (!review) return <div className="mt-3 mb-2">An unexpected error occurred. Please try again later.</div>

  return (
    <div className="report-wrapper" dangerouslySetInnerHTML={{ __html: review ? review.data.attributes.content : "" }}></div>
  );       
}