import React from 'react'
import {Card, CardProps, Button} from "../../components";
import useMonthlyReviews, { MonthlyReview, useLatestMonthlyReview } from '../hooks/useMonthlyReviews';
import {ENDPOINT_STATUSES} from "ssomg-react";
const {SUCCESS, ERROR} = ENDPOINT_STATUSES;

export interface AccountManagerCardProps extends CardProps {
  monthlyReviews: ReturnType<typeof useMonthlyReviews>,
}

export default function AccountManagerCard ({ monthlyReviews}: AccountManagerCardProps) {
  const latestReview = useLatestMonthlyReview(monthlyReviews);
  return (
    <Card>
      <Card.Subtitle>Point of Contact</Card.Subtitle>
      <AccountManagerCardView latestReview={latestReview} status={monthlyReviews.status}/>
    </Card>
  );
}

function AccountManagerCardView({status, latestReview} : {status: number, latestReview: MonthlyReview | null}) {
  if ( status === ERROR ) return  <div className="mt-2">An error occurred. Please try again later.</div>;
  if ( status !== SUCCESS ) return <Card.Loading/>;
  if ( !latestReview || latestReview.data.attributes.author.trim().length === 0 ) return <div className="mt-2">No assigned account manager</div>;
  const { author } = latestReview.data.attributes;
  const hyphenatedName = author.toLowerCase().split(" ").join("-");
  const dottedName = author.toLowerCase().split(" ").join(".");
  return  <div className="flex items-center justify-between mt-4">
    <div className="w-16 relative">
      <div className="w-full h-full rounded-full overflow-hidden">
        <img src={`/images/staff-photos/${hyphenatedName}.png`} alt={`Roar Staff Member ${author}`} className="object-cover object-center w-full h-full visible group-hover:hidden" />
      </div>
    </div>    
    <Button href={`mailto:${dottedName}@roardigital.co.uk`} className="font-bold text-blue-roar bg-yellow-roar py-2.5 px-4 rounded text-center">Email {author.split(" ")[0]}</Button>
  </div>
}