import React, {useState, useEffect} from 'react';
import {Card, CardProps} from "../../components";
import { ClientAccount } from '../hooks/useClientAccounts';
import useMonthlyReviews, { MonthlyReview } from '../hooks/useMonthlyReviews';
import {ENDPOINT_STATUSES} from "ssomg-react";
import { Link } from 'react-router-dom';
const {SUCCESS, ERROR} = ENDPOINT_STATUSES;

const Months = ["January","February","March","April","May","June","July","August","September","October","November","December"];
function toMonth(n:number) {
  if ( n >= 1 && n <= 12 ) return Months[n-1];
  return n;
}

export interface ArchiveCardProps extends CardProps {
  currentAccount: ClientAccount | null,
  monthlyReviews: ReturnType<typeof useMonthlyReviews>,
}

type Archive = ({year: number, reviews: MonthlyReview[]})[];

export default function ArchiveCard({currentAccount, monthlyReviews}:ArchiveCardProps) {
  const [archive, setArchive] = useState<Archive>([]);

  useEffect(() => {
    let archiveUpdate : Archive = [];
    let currentYear : MonthlyReview[] = [];
    let last: MonthlyReview | undefined;
    if ( !monthlyReviews.data ) {
      setArchive([]);
      return;
    }
    monthlyReviews.data
    .sort((a,b) => {
      if ( a.data.attributes.for_year === b.data.attributes.for_year ) {
        return a.data.attributes.for_month - b.data.attributes.for_month;
      } else {
        return a.data.attributes.for_year - b.data.attributes.for_year;
      }
    })
    .forEach(review => {
      if ( last ) {
        if (last.data.attributes.for_year === review.data.attributes.for_year) {
          currentYear.unshift(review);
        } else {
          archiveUpdate.unshift({year: last.data.attributes.for_year, reviews: currentYear});
          currentYear = [review];
        }
      } else {
        currentYear.unshift(review);
      }
      last = review;
    });
    if (currentYear.length > 0 && last) {
      archiveUpdate.unshift({year: last.data.attributes.for_year, reviews: currentYear});
    }
    setArchive(archiveUpdate);
  }, [monthlyReviews.data]);

  return (
    <Card>
      <Card.Subtitle>Report Archive</Card.Subtitle>
      <Link className="block text-gray-500 hover:text-gray-800 transition-colors hover:underline mt-1 text-sm" to={`/${currentAccount?.data.attributes.slug}`}>Latest Report</Link>
      <ArchiveCardView archive={archive} status={monthlyReviews.status} currentAccount={currentAccount}/>
    </Card>
  );
}

function ArchiveCardView({status, archive, currentAccount} : {status: number, archive: Archive, currentAccount: ClientAccount | null}) {
  if ( status === ERROR ) return  <div className="mt-2">An error occurred. Please try again later.</div>;
  if ( status !== SUCCESS ) return <Card.Loading/>;
  if ( archive.length === 0  ) return <div className="mt-2">Archive Unavailable</div>;
  return <>
    {archive.map(year => (
      <details key={year.year}>
        <summary className="cursor-pointer mt-1">{year.year}</summary>
        {year.reviews.map(review=>(
          <Link key={`${review.data.type}-${review.data.id}`} className="block text-gray-500 hover:text-gray-800 transition-colors hover:underline pl-6 text-sm" to={`/${currentAccount?.data.attributes.slug}/${year.year}/${review.data.attributes.for_month}`}>{toMonth(review.data.attributes.for_month)}</Link>
        ))}
      </details>
    ))}
  </>;
}